export default function Chat() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_493_32077)">
        <path
          d="M13.3333 0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 10.6667C0 11.3739 0.280952 12.0522 0.781049 12.5523C1.28115 13.0524 1.95942 13.3333 2.66667 13.3333H4.6L7.56733 15.842C7.68771 15.9439 7.8403 15.9998 7.998 15.9998C8.1557 15.9998 8.30829 15.9439 8.42867 15.842L11.4 13.3333H13.3333C14.0406 13.3333 14.7189 13.0524 15.219 12.5523C15.719 12.0522 16 11.3739 16 10.6667V2.66667C16 1.95942 15.719 1.28115 15.219 0.781049C14.7189 0.280952 14.0406 0 13.3333 0ZM4.66667 3.33333H8C8.17681 3.33333 8.34638 3.40357 8.47141 3.5286C8.59643 3.65362 8.66667 3.82319 8.66667 4C8.66667 4.17681 8.59643 4.34638 8.47141 4.4714C8.34638 4.59643 8.17681 4.66667 8 4.66667H4.66667C4.48986 4.66667 4.32029 4.59643 4.19526 4.4714C4.07024 4.34638 4 4.17681 4 4C4 3.82319 4.07024 3.65362 4.19526 3.5286C4.32029 3.40357 4.48986 3.33333 4.66667 3.33333ZM11.3333 10H4.66667C4.48986 10 4.32029 9.92976 4.19526 9.80474C4.07024 9.67971 4 9.51014 4 9.33333C4 9.15652 4.07024 8.98695 4.19526 8.86193C4.32029 8.7369 4.48986 8.66667 4.66667 8.66667H11.3333C11.5101 8.66667 11.6797 8.7369 11.8047 8.86193C11.9298 8.98695 12 9.15652 12 9.33333C12 9.51014 11.9298 9.67971 11.8047 9.80474C11.6797 9.92976 11.5101 10 11.3333 10ZM11.3333 7.33333H4.66667C4.48986 7.33333 4.32029 7.26309 4.19526 7.13807C4.07024 7.01305 4 6.84348 4 6.66667C4 6.48986 4.07024 6.32029 4.19526 6.19526C4.32029 6.07024 4.48986 6 4.66667 6H11.3333C11.5101 6 11.6797 6.07024 11.8047 6.19526C11.9298 6.32029 12 6.48986 12 6.66667C12 6.84348 11.9298 7.01305 11.8047 7.13807C11.6797 7.26309 11.5101 7.33333 11.3333 7.33333Z"
          fill="#E13737"
        />
      </g>
      <defs>
        <clipPath id="clip0_493_32077">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
