import React, { useState } from "react";

const MessageInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-96 w max-w-md mx-auto mt-4">
      <div className="flex items-center border rounded-lg overflow-hidden">
        <input
          title="Сообщение"
          type="text"
          className="w-full px-4 py-2 border-b-[1px] border-b-black/50 outline-none bg-transparent"
          placeholder="Введите сообщение"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <button className="hover:opacity-50 bg-[#E13737] p-4 w-full justify-center items-center flex rounded-full mt-4">
        <p className="text-xl text-white font-semibold">Отправить</p>
      </button>
    </form>
  );
};

export default MessageInput;
