import { FaUser, FaPhone, FaRegFileLines } from "react-icons/fa6";

function SelectedCertificate({ data }) {

  const downloadAndOpenCertificate = () => {
    const link = document.createElement("a");
    const baseUrl = "https://spasateli.kz/api/admin/certificate/";
    const fullUrl = `${baseUrl}${data.certificate}`;
    link.href = fullUrl;
    link.setAttribute("download", data.certificate.split('/').pop());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.open(link.href, "_blank");
  };

  const accept = async () => {
    try {
      const response = await fetch(`https://spasateli.kz/api/admin/rescuer/accept/${data.user_id}`);

      const responseJson = await response.json();

      if (responseJson.success) {
        alert('Спасатель успешно одобрен');
        window.location.reload();
      }
    } catch (error) {
      console.log('accept error: ', error);
    }
  }

  return (
    <div className="w-full flex flex-row justify-center items-center">
      <div className="w-96">
        <div className="w-full p-2">
          <div className="flex flex-col space-y-2 mb-3">
            <div className="flex flex-row items-center justify-between space-x-2">
              <div className="flex flex-row items-center">
                <FaUser className="text-xl text-[#7D8F9D]" />
                <p className="text-lg text-[#1F1F1F]/70">Имя и Фамилия</p>
              </div>
            </div>
            <div className="flex flex-row items-center space-x-6 justify-between">
              <div className="border-b-[1px] border-[#1F1F1F]/30 pb-1 w-full">
                <p className="text-lg text-black">{data.name}</p>
              </div>
              <div className="border-b-[1px] border-[#1F1F1F]/30 pb-1 w-full">
                <p className="text-lg text-black">{data.surname}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-3">
            <p className="text-lg text-[#1F1F1F]/70">Номер телефона</p>
            <div className="w-full border-b-[1px] border-[#1F1F1F]/30 pb-1 flex flex-row items-center justify-between">
              <p className="text-lg text-black">{data.phone}</p>
              <FaPhone className="text-xl text-[#7D8F9D]" />
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-lg text-[#1F1F1F]/70">Сертификат</p>
            <div className="w-full border-b-[1px] border-[#1F1F1F]/30 pb-1 flex flex-row items-center justify-between">
              <p className="text-lg text-black w-full">{data.certificate}</p>
              <FaRegFileLines className="text-xl text-[#7D8F9D]" />
            </div>
          </div>
        </div>
        <button onClick={() => downloadAndOpenCertificate()} className="w-full mt-3 rounded-full hover:opacity-50 p-4 justify-center items-center flex bg-[#E13737]">
          <p className="text-xl text-white">Скачать сертификат</p>
        </button>
        <button onClick={() => accept()} className="w-full mt-3 rounded-full p-4 hover:opacity-50 justify-center items-center flex border-2 border-[#E13737]">
          <p className="text-xl text-[#E13737]">Одобрить</p>
        </button>
      </div>
    </div>
  )
};

export default SelectedCertificate;