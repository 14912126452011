import React, { useState } from "react";

function ImageSlot({ photo, onChange }) {
  const [showPlus, setShowPlus] = useState(false);
  console.log("photo: ", photo);

  return (
    <div
      className="bg-white border border-gray-300 rounded-lg flex items-center justify-center h-20 w-20 relative"
      onMouseEnter={() => setShowPlus(true)}
      onMouseLeave={() => setShowPlus(false)}
    >
      {photo ? (
        <img src={photo} alt="preview" className="h-full w-full object-cover rounded-lg" />
      ) : (
        <>
          {showPlus && <button className="text-[#E13737] text-4xl">+</button>}
          <input
            type="file"
            accept="image/*"
            onChange={onChange}
            className="absolute inset-0 opacity-0 cursor-pointer"
          />
        </>
      )}
    </div>
  );
}

export default ImageSlot;
