import React, { useState, useEffect } from "react";
import axios from "axios";
import ChatSection from "./ChatSection";
import MessageInput from "./MessageInput";
import StatusSection from "./StatusSection";

const ExtendedChat = ({ chatId, userId }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMessages();
  }, [chatId]);



  const fetchMessages = async () => {
    try {
      const response = await fetch(`https://spasateli.kz/api/admin/chats/messages/${userId}`);

      const responseJson = await response.json();

      if (responseJson.success) {
        setMessages(responseJson.items);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async (message) => {
    try {
      const response = await fetch('https://spasateli.kz/api/user/tech-support/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: userId,
          admin_id: 20,
          role: 'admin',
          message: message
        })
      });

      fetchMessages(); // Refresh messages after sending
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="min-h-screen p-8 flex items-start w-full justify-center">
      <ChatSection messages={messages} loading={loading} />
      <MessageInput onSendMessage={handleSendMessage} />
      {/* <StatusSection /> */}
    </div>
  );
};

export default ExtendedChat;
