import React from "react";

const ChatSection = ({ messages, loading }) => {
  if (loading) {
    return <p>Loading messages...</p>;
  }

  console.log("messages: ", messages[0]);

  return (
    <div className="flex-1 overflow-y-auto p-4 bg-gray-100 rounded-lg flex-col">
      {messages.map((msg) => (
        <div key={msg.message_id} className="mb-4 flex-1 flex w-96 flex-row items-center">
          {msg.role === 'user' ? (
            <img src={`https://spasateli.kz/api/user/avatar/${msg?.photo}`} alt="user_photo" className="h-12 w-12 rounded-full" />
          ) : (
            <div className="h-12 w-12 rounded-full bg-[#E13737] flex justify-center items-center">
              <p className="text-xl text-white">ВЫ</p>
            </div>
          )}
          <div className="flex flex-col ml-4 items-start justify-start">
            <div className="text-sm text-gray-600">
              {new Date(msg.sent_at).toLocaleString()}
            </div>
            <div className="text-lg">{msg.message}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatSection;
