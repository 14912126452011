import React, { useState } from "react";
import MainHeader from "../MainHeader";
import BurgerMenu from "../BurgerMenu";

const CreateNotification = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [data, setData] = useState({
    title: '',
    message: ''
  });

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const sendMessage = async () => {
    try {
      const response = await fetch('https://spasateli.kz/api/admin/send-notifications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title: data.title,
          message: data.message
        })
      });

      const responseJson = await response.json();
      if (responseJson.success) {
        alert("PUSH рассылка успешно отправлена");
      }
    } catch (error) {
      console.log(`send message error`, error);
    }
  };

  return (
    <div className="w-full h-full">
      <MainHeader toggleMenu={toggleMenu} />
      <div className="w-full h-[80vh] flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md my-auto">
          <h2 className="text-center text-lg font-semibold mb-4">
            Написать PUSH-оповещение
          </h2>
          {/* UI Components */}
          <div className="mb-4">
            <label className="block text-gray-700">Уведомление</label>
            <input
              name="title"
              value={data.title}
              onChange={handleChangeData}
              className="mt-2 text-gray-700 w-full outline-none placeholder:text-wrap "
              placeholder="Введите заголовок уведомления"
            />
            <hr className="border-gray-300 mt-2" />
            <textarea
              name="message"
              value={data.message}
              onChange={handleChangeData}
              className="mt-2 text-gray-700 w-full outline-none h-28 placeholder:text-wrap"
              placeholder="Введите текст уведомления"
            />
            <hr className="border-gray-300 mt-2" />
          </div>
          <button
            className="bg-[#E13737] text-white font-semibold py-2 px-4 rounded-full w-full"
            onClick={sendMessage}
          >
            Отправить
          </button>
        </div>
      </div>
      <BurgerMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
    </div>
  );
};

export default CreateNotification;
