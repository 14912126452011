import { useEffect, useState } from "react";
import CertificateCard from "./CertificateCard";
import SelectedCertificate from "./SelectedCertificate";

function AllCertifications() {
  const [tab, setTab] = useState(0);
  const [rescuers, setRescuers] = useState([]);
  const [selectedRescuer, setSelectedRescuer] = useState(null);

  useEffect(() => {
    fetchAllRescuers();
  }, []);

  const fetchAllRescuers = async () => {
    try {
      const response = await fetch('https://spasateli.kz/api/admin/rescuers');

      const responseJson = await response.json();

      if (responseJson.success) {
        setRescuers(responseJson.rescuers);
      }
    } catch (error) {
      console.log('fetch rescuers error: ', error);
    }
  }

  const selectRescuer = (rescuer) => {
    console.log("selectRescuer: ", rescuer);
    setSelectedRescuer(rescuer);
    setTab(1);
  }

  return (
    <div className="w-full p-4 flex justify-center items-center">
      <div className="w-8/12">
        {tab === 0 && (
          <div className="grid w-full grid-cols-3 gap-4">
            {rescuers && rescuers.map((item, index) => (
              <CertificateCard
                key={index}
                data={item}
                selectRescuer={selectRescuer}

              />
            ))}
          </div>
        )}
        {tab === 1 && <SelectedCertificate data={selectedRescuer} />}
      </div>
    </div>
  );
};

export default AllCertifications;
