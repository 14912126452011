import { FaUser, FaPhone, FaRegFileLines } from "react-icons/fa6";

function CertificateCard({ data, selectRescuer }) {
  return (
    <div onClick={() => selectRescuer(data)} className="w-80 p-2 hover:opacity-75 cursor-pointer  ">
      <div className="flex flex-col space-y-2 mb-3">
        <div className="flex flex-row items-center justify-between space-x-2">
          <div className="flex flex-row items-center">
            <FaUser className="text-xl text-[#7D8F9D]" />
            <p className="text-lg text-[#1F1F1F]/70">Имя и Фамилия</p>
          </div>
          <p className="text-lg text-[#E13737]">Не одобрено</p>
        </div>
        <div className="flex flex-row items-center space-x-6 justify-between">
          <div className="border-b-[1px] border-[#1F1F1F]/30 pb-1 w-full">
            <p className="text-lg text-black">{data.name}</p>
          </div>
          <div className="border-b-[1px] border-[#1F1F1F]/30 pb-1 w-full">
            <p className="text-lg text-black">{data.surname}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col mb-3">
        <p className="text-lg text-[#1F1F1F]/70">Номер телефона</p>
        <div className="w-full border-b-[1px] border-[#1F1F1F]/30 pb-1 flex flex-row items-center justify-between">
          <p className="text-lg text-black">{data.phone}</p>
          <FaPhone className="text-xl text-[#7D8F9D]" />
        </div>
      </div>
      <div className="flex flex-col">
        <p className="text-lg text-[#1F1F1F]/70">Сертификат</p>
        <div className="w-full border-b-[1px] border-[#1F1F1F]/30 pb-1 flex flex-row items-center justify-between">
          <p className="text-lg text-black w-full">{data.certificate}</p>
          <FaRegFileLines className="text-xl text-[#7D8F9D]" />
        </div>
      </div>
    </div>
  )
};

export default CertificateCard;