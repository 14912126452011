import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import TabSwitcher from "./TabSwitcher";
import axios from "axios";

const AddStudyCenterForm = ({ setTab, tab }) => {
  const [data, setData] = useState({
    address: '',
    email: '',
    latitude: 0,
    longitude: 0,
    contact_phone: '',
    fromTimeWork: '',
    toTimeWork: '',
    telegram: '',
    instagram: '',
    whatsapp: '',
    entity: ''
  });
  const [isSave, setIsSave] = useState(false);
  const [placeholders, setPlaceholders] = useState([]);
  const [isPlaceholder, setIsPlaceholder] = useState(false);

  useEffect(() => {
    formValidation();
  }, [data]);

  const formValidation = () => {
    // Проверяем, заполнены ли все поля
    const allFieldsFilled = Object.values(data).every(value => value !== '' && value !== null && value !== undefined);

    // Если все поля заполнены, устанавливаем isSave в true, иначе в false
    setIsSave(allFieldsFilled);
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleChangeAddress = async (address) => {
    setData(prevData => ({
      ...prevData,
      address: address
    }));
    setIsPlaceholder(true); // Установить состояние true при вводе текста
    const suggestions = await fetchAddressSuggestions(address);
    setPlaceholders(suggestions);
  };

  const fetchAddressSuggestions = async (inputText) => {
    try {
      const apiKey = 'AIzaSyAoeJsYR20gUXEXBtXDM49xoNYByvFAbZg';
      const autocompleteUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${inputText}&key=${apiKey}`;

      const autocompleteResponse = await axios.get(autocompleteUrl);

      if (autocompleteResponse.data.predictions) {
        const predictions = autocompleteResponse.data.predictions;
        const suggestions = [];

        // Перебираем предложенные адреса
        for (const prediction of predictions) {
          const placeId = prediction.place_id;
          const placeDetailsUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;

          // Делаем запрос на получение географических данных по place_id
          const placeDetailsResponse = await axios.get(placeDetailsUrl);

          // Парсим ответ и извлекаем широту и долготу
          const geometry = placeDetailsResponse.data.results[0].geometry;
          const location = geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;

          // Добавляем адрес и его географические координаты в список предложений
          suggestions.push({
            address: prediction.description,
            latitude: latitude,
            longitude: longitude
          });
        }

        return suggestions;
      }

      return [];
    } catch (error) {
      console.log('Error fetching address suggestions:', error);
      return [];
    }
  };

  const handleChangeEntity = (entity) => {
    setData(prevData => ({
      ...prevData,
      entity: entity
    }));
  };

  return (
    <div className="h-max flex items-center justify-center w-[55%] mt-2">
      <div className="bg-white rounded-lg w-full mx-auto">
        <TabSwitcher tab={tab} setTab={setTab} />
        <h2 className="text-gray-900 font-semibold text-xl mb-2">Информация</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
          <div>
            <label className="block text-gray-700 mb-1">Адрес центра</label>
            <input
              name="address"
              value={data.address}
              onChange={(e) => handleChangeAddress(e.target.value)}
              type="text"
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              placeholder="Введите адрес центра"
            />
            {isPlaceholder && data.address && (
              <div className="flex flex-col items-start justify-start space-y-1.5 mt-2">
                {placeholders.map((item, index) => (
                  <button key={index} className="text-lg text-black hover:opacity-50">
                    {item.address}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Email</label>
            <input
              name="email"
              value={data.email}
              onChange={handleChangeData}
              type="email"
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              placeholder="Введите email"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Контактный номер</label>
            <InputMask
              mask="+7 (999) 999-99-99"
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              placeholder="+7 (___) ___-__-__"
              name="contact_phone"
              value={data.contact_phone}
              onChange={handleChangeData}
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">
              Юридическая сущность
            </label>
            <select
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              value={data.entity}
              onChange={(e) => handleChangeEntity(e.target.value)}
            >
              <option value="" disabled>Выберите сущность</option>
              <option value="ИП">Для ИП</option>
              <option value="Юр.лицо">Для Юр.лиц</option>
              <option value="Физ.лицо">Для Физ.лиц</option>
            </select>
          </div>
        </div>
        <h2 className="text-gray-900 font-semibold text-xl mb-2">
          Часы работы
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
          <div>
            <label className="block text-gray-700 mb-1">От</label>
            <input
              type="text"
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              placeholder="10:00"
              name="fromTimeWork"
              value={data.fromTimeWork}
              onChange={handleChangeData}
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">До</label>
            <input
              type="text"
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              placeholder="20:00"
              name="toTimeWork"
              value={data.toTimeWork}
              onChange={handleChangeData}
            />
          </div>
        </div>
        <h2 className="text-gray-900 font-semibold text-xl mb-2">Соц.сети</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
          <div>
            <label className="block text-gray-700 mb-1">Instagram</label>
            <input
              type="text"
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              placeholder="Введите Instagram"
              name="instagram"
              value={data.instagram}
              onChange={handleChangeData}
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">WhatsApp</label>
            <InputMask
              mask="+7 (999) 999-99-99"
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              placeholder="+7 (___) ___-__-__"
              name="whatsapp"
              value={data.whatsapp}
              onChange={handleChangeData}
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Telegram</label>
            <input
              type="text"
              className="w-full border-b border-gray-300  p-2 focus:outline-none focus:border-gray-500"
              placeholder="Введите Telegram"
              name="telegram"
              value={data.telegram}
              onChange={handleChangeData}
            />
          </div>
        </div>
        <div className="w-full flex justify-center">
          <button
            className={`bg-[#E13737] text-white font-semibold py-2 px-4 rounded-full w-1/2 ${isSave ? 'hover:opacity-50' : 'opacity-50 cursor-not-allowed'}`}
            disabled={!isSave}
          >
            Добавить
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddStudyCenterForm;
