import React from "react";
import Chat from "../Chat";

const AllChats = ({ chats, setTab }) => {

  console.log("AllChats: ", chats);

  return (
    <div className="min-h-screen p-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {chats.map((chat) => (
          <Chat
            key={chat.chat_id}
            name={`${chat.name} ${chat.surname}`}
            description={chat.last_message}
            avatarUrl={chat.photo}
            notifications={chat.unread_messages}
            onClick={() => setTab({ type: 5, id: chat.chat_id, userId: chat.sender_id })}
          />
        ))}
      </div>
    </div>
  );
};

export default AllChats;
