import React, { useState, useEffect } from "react";
import axios from "axios";
import AddStudyCenterForm from "./AddStudyCenterForm";
import Map from "./Map";
import MainHeader from "../MainHeader";
import BurgerMenu from "../BurgerMenu";
import ReactLoading from 'react-loading';

function StudyCenters() {
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    fetchStudyCenters();
  }, []);

  const fetchStudyCenters = async () => {
    try {
      const response = await axios.get(
        "https://spasateli.kz/api/user/study-centers",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("response.data", response.data);
      setCenters(response.data.centers);
    } catch (error) {
      console.error("Failed to fetch study centers:", error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className="flex flex-col w-full items-center">
      <MainHeader toggleMenu={toggleMenu} />
      {loading && (
        <div className="w-full h-[80vh] justify-center items-center flex">
          <ReactLoading type="spin" color="#E13737" height={30} width={30} />
        </div>
      )}
      {!loading && (
        <>
          <BurgerMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
          {tab === 0 && <Map setTab={setTab} tab={tab} centers={centers} />}
          {tab === 1 && <AddStudyCenterForm setTab={setTab} tab={tab} />}
        </>
      )}
    </div>
  );
}

export default StudyCenters;
