export default function Bars() {
  return (
    <svg
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 2.22913C29 3.23204 28.1904 4.04163 27.1875 4.04163H1.8125C0.809583 4.04163 0 3.23204 0 2.22913C0 1.22621 0.809583 0.416626 1.8125 0.416626H27.1875C28.1904 0.416626 29 1.22621 29 2.22913ZM7.85417 22.1666H1.8125C0.809583 22.1666 0 22.9762 0 23.9791C0 24.982 0.809583 25.7916 1.8125 25.7916H7.85417C8.85708 25.7916 9.66667 24.982 9.66667 23.9791C9.66667 22.9762 8.85708 22.1666 7.85417 22.1666ZM17.5208 11.2916H1.8125C0.809583 11.2916 0 12.1012 0 13.1041C0 14.107 0.809583 14.9166 1.8125 14.9166H17.5208C18.5238 14.9166 19.3333 14.107 19.3333 13.1041C19.3333 12.1012 18.5238 11.2916 17.5208 11.2916Z"
        fill="#E13737"
      />
    </svg>
  );
}
