import React, { useState, useEffect } from "react";
import axios from "axios";
import MainHeader from "../MainHeader";
import BurgerMenu from "../BurgerMenu";
import Back from "./../../assets/images/back";
import AllChats from "./All/AllChats";
import ExtendedChat from "./ExtendedChat/ExtendedChat";
import ReactLoading from 'react-loading';

function ModerationChat() {
  const [chats, setChats] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [tab, setTab] = useState({ type: 0, id: null, userId: null });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchChats();
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const fetchChats = async () => {
    try {
      const response = await fetch('https://spasateli.kz/api/admin/chats/all');
      const responseJson = await response.json();

      console.log("chats: ", responseJson.items);
      if (responseJson.success) {
        setChats(responseJson.items); // Сохраняем весь массив items
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen w-screen">
      <MainHeader toggleMenu={toggleMenu} />
      <h1 className="text-center text-2xl font-semibold mt-8 mb-2">
        Все пользователи
      </h1>
      {isLoading && (
        <div className="w-full h-96 flex justify-center items-center">
          <ReactLoading type="spin" color="#E13737" height={30} width={30} />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="w-full flex items-center relative">
            {(tab.type === 5 || tab.type === 3) && (
              <div
                className="absolute left-32 -top-0 font-medium text-lg flex items-center gap-4 cursor-pointer"
                onClick={() => setTab({ type: 0, id: null, userId: null })}
              >
                <Back />
                Назад
              </div>
            )}
            <div className="w-max flex mx-auto mb-8 text-xl text-[#1F1F1F] gap-6 font-medium relative">
              <span
                onClick={() => setTab({ type: 0, id: null, userId: null })}
                className={
                  "cursor-pointer px-2 " +
                  (tab.type === 0 || tab.type === 5
                    ? `opacity-100 border-b-2 border-[#1F1F1F]`
                    : `opacity-40`)
                }
              >
                Все
              </span>
            </div>
          </div>
          {chats && chats.length > 0 ? (
            <>
              {tab.type === 0 && <AllChats chats={chats} setTab={setTab} />}
            </>
          ) : (
            <p className="text-center text-[#141414]/50 text-xl mt-24">Пока никто из пользователей не обращался</p>
          )}
          {tab.type === 5 && <ExtendedChat chatId={tab.id} userId={tab.userId} />}
        </>
      )}
      <BurgerMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
    </div>
  );
}

export default ModerationChat;
