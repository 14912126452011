export default function Logo() {
  return (
    <svg
      width="50"
      className="mb-3"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#E13737" />
      <path
        d="M37 17.0117V29.5423C36.0214 44.2862 17.117 44.9623 14.0163 30.6319C13.8516 29.8682 13.653 29.1094 13.435 28.3602C12.621 25.5438 12.9408 24.0456 14.4669 23.8023C15.5618 23.6272 16.6374 24.2207 17.1412 25.213C18.2555 27.3874 18.9241 29.6201 19.7138 30.52C19.8446 30.6708 20.0142 30.7876 20.2031 30.8411C21.5258 31.2108 19.2245 27.4506 18.1344 24.9211C17.805 24.1623 17.4852 23.4083 17.4852 22.5814V13.4948C17.4852 12.4295 18.3427 11.5685 19.4037 11.5685C20.4647 11.5685 21.3223 12.4295 21.3223 13.4948V20.7281C21.3223 21.0394 21.4773 21.3458 21.7486 21.4966C22.0005 21.6377 22.2961 21.6328 22.5286 21.3993C22.6885 21.2388 22.766 21.0151 22.766 20.7913V10.9799C22.766 9.91458 23.6042 9.00981 24.6652 9.00008C25.2029 8.99522 25.6923 9.20925 26.0411 9.56435C26.3899 9.91458 26.6031 10.3962 26.6031 10.9264V22.5619C26.6031 22.9268 26.855 23.2478 27.2135 23.3159C27.2184 23.3159 27.2232 23.3159 27.2329 23.3208C27.6011 23.384 27.9403 23.0873 27.9403 22.7079V13.3294C27.9403 12.2592 28.7881 11.3447 29.8539 11.3447C30.3869 11.3447 30.8665 11.5587 31.2153 11.909C31.5641 12.2592 31.7773 12.7408 31.7773 13.271V25.3395C31.7773 25.4611 31.7822 25.5778 31.8015 25.6994C32.0099 26.9788 33.1678 26.8474 33.1678 25.4854V17.0701C33.1678 15.9999 34.0156 15.0854 35.0815 15.0854C35.6144 15.0854 36.094 15.2994 36.4429 15.6497C36.7868 15.995 37 16.4766 37 17.0117Z"
        fill="white"
      />
    </svg>
  );
}
