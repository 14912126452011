import React, { useState } from "react";
import ImageSlot from "./ImageSlot";

const CreateArticle = () => {
  const [data, setData] = useState({
    title: '',
    description: '',
    photo1: null,
    photo2: null
  });

  const handleChangeData = (fieldName, value) => {
    setData(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  const handleImageChange = (fieldName, e) => {
    const file = e.target.files[0];
    if (file) {
      handleChangeData(fieldName, file); // Сохраняем непосредственно файл
    }
  };

  const addDisaster = async () => {
    const formData = new FormData();
    formData.append('photo', data.photo1);
    formData.append('photo_preview', data.photo2);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("type", "Системные");
    formData.append("user_id", 20); // предполагается, что userId доступен
    formData.append("date", new Date().toLocaleDateString()); // Используем ISO формат даты
    console.log("data: ", data);

    try {
      const response = await fetch('https://spasateli.kz/api/user/disasters/add-disaster', {
        method: 'POST',
        body: formData
      });

      const responseJson = await response.json();
      console.log(responseJson);

      if (responseJson.success) {
        alert("Стихийное бедствие успешно создано");
        window.location.reload();
      }
    } catch (error) {
      console.log("addDisaster: ", error);
    }
  };

  return (
    <div className="flex items-start mt-8 justify-center h-screen">
      <div className="p-6 w-[50%] mx-auto flex flex-col">
        <div className="flex mb-4">
          <div className="flex-1 mr-4">
            <label className="block text-gray-700 mb-1">Заголовок</label>
            <input
              type="text"
              className="w-full border-b border-gray-300 py-2 focus:outline-none focus:border-gray-500"
              placeholder="Будет возможно землетрясение"
              value={data.title}
              onChange={(e) => handleChangeData("title", e.target.value)}
            />
            <label className="block text-gray-700 mt-4 mb-1">Фото</label>
            <div className="grid grid-cols-4 gap-2">
              {Array(2)
                .fill()
                .map((_, i) => (
                  <ImageSlot
                    key={i}
                    photo={i === 0 && data.photo1 ? URL.createObjectURL(data.photo1) : i === 1 && data.photo2 ? URL.createObjectURL(data.photo2) : null} // Для предпросмотра
                    onChange={(e) => handleImageChange(i === 0 ? 'photo1' : 'photo2', e)}
                  />
                ))}
            </div>
          </div>
          <div className="flex-1 ml-4">
            <label className="block text-gray-700 mb-1">Описание</label>
            <textarea
              className="w-full border-b border-gray-300 py-2 focus:outline-none focus:border-gray-500"
              rows="10"
              placeholder="Хочу поделиться важной информацией о возможном землетрясении..."
              value={data.description}
              onChange={(e) => handleChangeData("description", e.target.value)}
            ></textarea>
          </div>
        </div>
        <button
          onClick={addDisaster}
          className="bg-[#E13737] text-white font-semibold py-2 px-4 rounded-full w-1/2 mx-auto"
        >
          Опубликовать
        </button>
      </div>
    </div>
  );
};

export default CreateArticle;
