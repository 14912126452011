import React from "react";

export default function back() {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.07752 11.4883L0.422516 6.83334C0.26629 6.67706 0.178528 6.46514 0.178528 6.24417C0.178528 6.0232 0.26629 5.81128 0.422516 5.655L5.07752 1C5.19406 0.883493 5.34253 0.804155 5.50416 0.772015C5.66578 0.739875 5.83331 0.756378 5.98556 0.819436C6.13781 0.882494 6.26795 0.989278 6.35952 1.12629C6.45109 1.26329 6.49998 1.42438 6.50002 1.58917V10.8992C6.49998 11.064 6.45109 11.225 6.35952 11.3621C6.26795 11.4991 6.13781 11.6058 5.98556 11.6689C5.83331 11.732 5.66578 11.7485 5.50416 11.7163C5.34253 11.6842 5.19406 11.6048 5.07752 11.4883Z"
        fill="#E13737"
      />
    </svg>
  );
}
